// list and overlay common styles
.juicer-feed.hhq-news, .j-post-overlay.juicer-feed {
    a:hover { color: $primary; }
    .j-poster {
        .j-date {
            color: $primary;
            background: $gray-300;
            padding: 4px;
            border-radius: 1.2em;
            min-width: 36px;
            text-align: center;
        }
    }
}

// list styles
.juicer-feed.hhq-news {
    padding-top: 5px;
    li.feed-item {
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        transition: transform .2s;
        &::after {
            content: " ";
            display: block;
            background: $primary;
            width: 0%;
            height: 3px;
            transition: width .2s;
        }
    }
    li.feed-item:hover {
        transform: translate(0, -5px);
        box-shadow: 0px 3px 6px rgba(0,0,0,0.3);
        &::after {
            width: 100%;
        }
    }
    .j-poster {
        h3, .twitter-poster {
            max-width: 60%;
        }
        .j-date {
            margin-top: 3px !important;
        }
    }
    .j-meta a { color: $primary; }
}

// overlay styles
.j-overlay {
    .j-close {
        padding: 0 !important;
        top: 12px !important;
        right: 12px !important;
    }
}
.j-post-overlay.juicer-feed {
    .j-poster {
        margin-top: 10px;
        .j-date {
            margin-top: 0px !important;
        }
    }
    a.juicer-button {
        color: $primary !important;
        border-color: $primary !important;
        &:hover {
            color: #fff !important;
            background: $primary !important;
        }
    }
    .j-navigate {
        bottom: 12px !important;
        right: 12px !important;
    }
}
