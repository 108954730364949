@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "../../styles/variables";

.page.login {
  z-index: 100;
  background: #696c7d url("../../images/background.jpg") no-repeat center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  .hqlogo {
    max-height: 100px;
    max-width: 92%;
    width: auto;
  }
  .container {
    @include media-breakpoint-up(md) {
      max-width: 720px;
    }
  }
  .version {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .5;
  }
}
