@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.hqbreadcrumb {
    color: $primary;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        display: inline-block;
        padding: 0;
        &::after {
            content: " ";
            color: $secondary;
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 14px;
            border-style: solid;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
        }
        &:last-child::after {
            display: none;
        }
    }
    a {
        color: $secondary;
    }
}
