@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.loader {
  text-align: center;
  &.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    &.primary {background: rgba($primary, 0.8);}
    &.secondary {background: rgba($secondary, 0.8);}
    &.dark {background: rgba($dark, 0.8);}
    &.light {background: rgba($light, 0.8);}
    &.white {background: rgba($white, 0.8);}
    &.transparent {background: transparent;}
  }
  &.placeholder {
    position: relative;
    height: 0;
    padding-top: 42%;
    &.primary {background: $primary;}
    &.secondary {background: $secondary;}
    &.dark {background: $dark;}
    &.light {background: $light;}
    &.white {background: $white;}
    &.transparent {background: transparent;}
  }
  .loader__inner {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
