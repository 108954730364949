// cart, checkout, orders

.page.cart, .page.checkout, .page.order-details, .page.saved-order-details {
    .cart-header {
        background: $gray-200;
        h1 {
            font-size: 1.5em;
            font-weight: bold;
        }
        .cart-details {
            font-size: 1rem;
            .price {
                font-size: 2rem;
            }
            .items-count, button {
                font-size: 1.1rem;
            }

            .items-count button {
                border: 0;
                padding: 0;
                margin: 0;
            }
        }
        .book-cover {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .book-cover-wrapper {
            @include media-breakpoint-up(sm) {
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
                transform-origin: left;
                width: 100%;
            }
        }
    }
}

.page.cart {
    .downloads {
        text-align: right;
    }
}

.page.checkout, .page.order-details {
    .cart-header {
        margin-top: calc(min(6rem, 8vw));
    }
}

.page.order-details, .page.saved-order-details {
    .cart-header {
        margin-bottom: 6rem;
    }
    .book-list-item {
        width: auto;
        .list-item {
            margin-left: auto;
            margin-right: auto;
            & > .row {
                @include media-breakpoint-up(lg) {
                    width: max-content;
                }
            }
        }
        .cover img {
            margin-right: auto;
            margin-left: auto;
            max-width: 100px;
        }
        .barcode {
            text-align: center;
            canvas {
                margin-left: -20px;
            }
        }
    }
    .order-note {
        display: flex;
        align-items: center;
    }
}

.page.saved-order-details {
    .cart-header {
        margin-bottom: calc(min(15vw,10em));
        margin-top: calc(min(12vw,10em));
    }
}

.page.saved-orders {
    .saved-date {
        width: 100%;
        font-size: 1.3em;
        @include media-breakpoint-down(sm) {
            font-size: 1.1em;
        }
        font-weight: bold;
    }
    .delete-control {
        z-index: 100;
        button {
            font-size: 2.5rem;
            &:hover {
                color: $red;
            }
        }
        svg {
            vertical-align: top;
            display: block;
        }
    }
}
