@import "~bootstrap/scss/functions";
@import "../../styles/variables";
@import "~bootstrap/scss/mixins/clearfix";

$hqpagination-height: 3.5em;

.hqpagination {
  overflow: hidden;
  position: relative;
  color: $dark;
  height: $hqpagination-height;
  line-height: $hqpagination-height;
  text-align: center;

  svg {
    vertical-align: text-bottom;
  }

  input {
      line-height: normal;
      width: 50px;
  }
  // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  input[type=number] {
      -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  &__items {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    @include clearfix;
  }

  &__item {
    display: block;
    margin: 0 1em;
    float: left;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__ellipsis,
  &__link,
  &__next,
  &__prev {
    color: inherit;
    text-decoration: none !important;
    outline: none !important;
    padding: 0;
    border: none;
    box-shadow: none;
    background: none;
    transition: color .15s linear;
  }

  &__link.active,
  &__link:hover:not(.disabled),
  &__next:hover:not(.disabled),
  &__prev:hover:not(.disabled) {
    color: $primary;
  }

  &__indicator {
    pointer-events: none;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: $hqpagination-height;
    height: $hqpagination-height;
    border: 1px solid $primary;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-50%, 0);
    transition: left .3s, width .3s, opacity .3s;
  }

  &__link,
  &__next,
  &__prev {
    &.disabled {
      cursor: not-allowed;
      opacity: .30;
      pointer-events: none;
    }
  }
}
