@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins/breakpoints";
@import "../../styles/variables";

.book-stack {
    position: relative;

    .inner {
        position: relative;
        height: 0;
        padding-top: 120%;
        margin: 0 15% 20% 0;
    }

    img {
        position: absolute;
        transition: transform .3s ease-in-out, top .3s ease-in-out, left .3s ease-in-out;
        transform-origin: bottom center;
        max-width: 100%;
        max-height: 100%;

        &:nth-of-type(1) {
            z-index: 100;
            left: 0;
            top: 0;
        }

        &:nth-of-type(2) {
            z-index: 99;
            left: 5%;
            top: 5%;
        }

        &:nth-of-type(3) {
            z-index: 98;
            left: 10%;
            top: 10%;
        }

        &:nth-of-type(4) {
            z-index: 97;
            left: 15%;
            top: 15%;
        }
    }
}
.book-stack.animate {
    &:hover, .release-list-item:hover & {
        img {
            &:nth-of-type(1) {
                transform: rotate(-5deg);
            }

            &:nth-of-type(2) {
                transform: rotate(5deg);
                left: 5%;
                top: 2%;
            }

            &:nth-of-type(3) {
                transform: rotate(10deg);
                left: 10%;
                top: 6%;
            }

            &:nth-of-type(4) {
                transform: rotate(15deg);
                left: 15%;
                top: 10%;
            }
        }
    }
}
