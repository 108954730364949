.page.book {
  
  ul, ol {
    padding-left: 1.2rem;
    li {
      padding-left: .3rem;
      margin-bottom: .3rem;
    }
  }
  
  .book-header {
    background: $gray-200;
    padding: 0 1rem;

    .book-cart {
        .input-group-prepend .input-group-text {
            background-color: $gray-400;
        }
    }

    .book-cover {
      position: relative;

      img {
        cursor: pointer;
        width: 100%;
      }
    }
    
    .price-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: baseline;
        .book-cart-wrapper {
            flex: 0 1 auto;
        }
    }
  }

  .book-summary {
    font-weight: bold;
    font-size: 1.1em;
  }

  .book-info {
    background: $white;
    border-radius: $border-radius;
    padding: 1rem;
    margin: 2rem 0;
    table {
      width: 100%;
    }
    td {
      padding: 5px 10px 5px 0;
      vertical-align: top;
    }
  }
  
  .videos {
    text-align: center;
    video {
        width: 100%;
        max-width: 800px;
        margin: 0 auto 1.2em;
    }
    .embed-wrapper {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      left: 0;
      position: relative;
      margin: 0 0 1.2em;

      & > iframe {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
      }
    }
  }

  .accordion.book-details {
    text-align: center;
    >div {
      text-align: left;
    }

    .bookdetails-toggle {
      color: $primary;
      border: none;
      border-top: 1px solid rgba(0,0,0,0.2);
      background: transparent;
      padding: 20px 0;
      width: 100%;
      @include media-breakpoint-up(sm) {
          width: calc(55% - 2rem);
      }
      @include media-breakpoint-up(md) {
          width: calc(65% - 2rem);
      }
      @include media-breakpoint-up(lg) {
          width: calc(75% - 2rem);
      }

      &::before {
        content: "Show ";
      }

      .up {
        display: none;
      }
    }

    .collapse.show + .bookdetails-toggle {
      &::before {
        content: "Hide ";
      }

      .up {
        display: inline-block;
      }

      .down {
        display: none;
      }
    }
    
    
  }
  
  .book-nav-btn {
      padding: $btn-padding-y;
      border-radius: 50%;
      margin-right: 1rem;
      svg { display: block; }
  }
  
  @include media-breakpoint-up(sm) {
      .book-cover-title {
          .left {
            float: left;
            width: 45%;
            margin-right: 2rem;
          }
      }
  }
  @include media-breakpoint-up(md) {
      .book-cover-title {
          .left {
            width: 35%;
          }
      }
  }
  @include media-breakpoint-up(lg) {
      .book-cover-title {
          .left {
            width: 25%;
          }
      }
      .book-header {
          
      }
      .other-books {
          width: 14%;
          max-width: 232px;
          height: auto;
          overflow-y: auto;
          &__list {
              position: absolute;
              padding-right: 16px;
          }
      }
      .list.releases {
          width: 100%;
      }
  }
}

.book-in-release .display-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #fff;
    > div {
        height: 100%;
        overflow-y: auto;
    }
}
