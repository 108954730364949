@import "~bootstrap/scss/functions";
@import "../../styles/variables";

.search-field {
  position: relative;
  margin-left: 25px;
  min-width: 25px;
  .icon-wrapper {
    background: $primary;
    color: $white;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    padding: 10px;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -25px;
    top: 0;
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  .go {
      position: absolute;
      top: 10px;
      right: 5px;
      background: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      width: 28px;
      height: 28px;
      color: $primary;
  }
  input {
    height: 50px;
    width: 100%;
    line-height: 50px;
    background: $gray-300;
    color: $gray-700;
    border-radius: 0 25px 25px 0;
    border: none;
    outline: none;
    padding-left: 0;
    padding-right: 0;
    max-width: 0;
    transition: max-width .5s, padding-left .5s, padding-right .5s;
  }
  &.expanded {
    input {
      max-width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
