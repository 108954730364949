
@import "fonts";

@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/print";

@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities/api";

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

@import "bs-styles";

#footer {
    @include media-breakpoint-down(md) {
        .navbar-nav {
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
        }
        .navbar-brand {
            margin: 0 auto;
        }
    }
    .version .version--inner {
      background: $dark;
    }
    .version .container {
      opacity: .7;
    }
    .version.hasupdate {
      height: 60px;
      .version--inner {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,0);
        width: 100%;
        z-index: 1000;
      }
    }
    .nav-link {
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        text-align: left;
    }
}

.form-group {
  margin-bottom: 1rem;
}

.localstorage-item {
    padding: 5px;
    border-radius: 3px;
    small {
        opacity: .6;
    }
    &:hover {
        background: #e8ebee;
    }
}

.product-row {
    // need 5 books per row on lg breakpoint
    @include media-breakpoint-up(lg) {
        > .col-6 {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

@import "components/list-items";

@import "components/sorting";

.page {
    position: relative;
}

.page-banner {
    .page-banner__text {
        background: #fff;
    }
    .page-banner__img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 0;
        padding-top: 26%;
    }
}

.page.home {
    .juicer-feed.hhq-news .feed-item .j-message > * {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 120px;
    }
}

.page.act-as-user {
  min-height: 280px;
}

.page.offer {
    .offer-description {
        font-size: 130%;
    }
}

@import "components/page-book";

@import "components/orders";

@import "components/stock-level";

.download-covers {
    position: relative;
    img {
        max-width: 8px;
        flex: 1 1 auto;
    }
}

.fixedalert {
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 60vw;
    z-index: 5000;
}

.borderless-btn {
    color: $primary;
    border: none;
    background: none;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      color: darken($primary, 7.5%)
    }
}

.invoices__heading {
  h2, .form-control {
    display: inline-block;
    width: auto;
  }
  .form-control {
    margin-left: 20px;
    transform: translate(0,-3px);
  }
}
.invoices__months {
  .dropdown-toggle {
    text-transform: capitalize;
  }
}

@import "juicer";
