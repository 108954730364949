// @import "~bootstrap/scss/functions";
// @import "../../styles/variables";

.book-cart {
    .cancel-wrapper {
        background: #fff;
        padding: 0 !important;
    }
    .cancel-button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: none;
        box-shadow: none;
    }
    .greyed {
        color: lightgrey;
    }
    input.qty {
        max-width: 80px;
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
}
