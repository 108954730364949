@import "~bootstrap/scss/functions";
@import "../../styles/variables";

$books-nav-height: 1em;
$books-nav-spacing: 1em;

.books-nav {
  position: relative;
  line-height: 1;
  max-width: 100%;
  overflow: hidden;
  border-bottom: 1px solid $gray-400;
  &.use-sticky {
      position: sticky;
      top: 0;
      background: $white;
      z-index: 10;
  }
}

.books-nav__panel {
  position: relative;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 15px 0 50px; // Hide Scrollbars
  margin-bottom: -50px;
  box-sizing: content-box;
}

.books-nav__items {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  align-items: flex-start;
  white-space: nowrap;
}

.books-nav__item {
  flex: 0 0 auto;
  margin-right: 5%;
  white-space: normal;
}

.books-nav__action {
  display: inline-block;
  width: 12vw;
  max-width: 200px;
  min-width: 100px;
  z-index: 1;
  position: relative;
  padding: 0 0 1em;
  vertical-align: baseline;
  text-align: left;
  transition-property: color, fill, stroke, width;
  transition-duration: .15s;
  transition-timing-function: linear;
  cursor: pointer;
  text-decoration: none !important;

  &.active,
  &:hover {
    color: $primary;
    fill: $primary;
    stroke: $primary;
    transition-delay: .15s;
  }

  &,
  &.disabled {
    color: $dark;
    fill: $dark;
    stroke: $dark;
  }

  &,
  &.disabled,
  &:active,
  &:focus {
    outline: 0;
    border: 0;
    background: none;
  }

  > span {
    position: relative;
  }

  svg {
    position: relative;
    margin-right: .75em;
    vertical-align: middle;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: .3;
  }

  .section-label, .book-title {
      font-size: 1.2em;
  }
  .price {
      font-size: .9em;
  }
  .section-label, .book-title, .price {
      transition: font-size .3s;
  }
}
a.books-nav__action,
.books-nav__action > a, .books-nav__action > a ~ * {
    opacity: .5;
}
.books-nav.none-selected a.books-nav__action,
.books-nav.none-selected .books-nav__action > a, .books-nav.none-selected .books-nav__action > a ~ *,
a.books-nav__action.active,
.books-nav__action > a.active, .books-nav__action > a.active ~ * {
    opacity: 1;
}

.books-nav__item.section {
  .section-list-item__label {
    font-size: 20px;
    font-weight: 600;
  }
}

.books-nav__item.book {
    // .book-title {
    //     display: -webkit-box;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;
    //     overflow: hidden;
    //     max-height: 70px;
    // }
    .cart-submit {display: none;}
    img {min-width: auto;}
}

.books-nav__item.link {
    a {
        color: $dark;
        font-size: 1.4rem;
        font-weight: bold;
    }
}

// Active indicator added by JS
.books-nav__indicator {
  pointer-events: none;
  display: block;
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 1px;
  height: 3px;
  background: $primary;
  transition: left .3s, width, opacity .3s;
}

// Paddles added by JS
.books-nav__paddle-left,
.books-nav__paddle-right {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 50px;
  padding: 0;
  height: 100%;
  z-index: 2;
  border: 0;
  outline: 0;
  overflow: hidden;
  transition: opacity .15s linear;
  color: $primary;

  &:disabled {
    width: 0;
    opacity: 0;
  }

  svg {
    padding: 10px;
    box-sizing: content-box;
    border: 1px solid;
    border-radius: 50%;
  }
}

.books-nav__paddle-left {
  left: 0;
  background: linear-gradient(to right, rgba(#fff,1) 40%,rgba(#fff,0) 100%);

  &::after {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }
}

.books-nav__paddle-right {
  right: 0;
  background: linear-gradient(to right, rgba(#fff,0) 0%,rgba(#fff,1) 60%);

  &::after {
    right: 0;
  }
}

.books-nav.sticky {
    .books-nav__panel {
        padding-left: 10px;
        padding-right: 10px;
    }
    .books-nav__action {
        width: 8vh;
        min-width: 70px;
    }
    .books-nav__action {
        .section-label {
            font-size: .8em;
        }
        .book-title, .price {
            display: none;
        }
    }
}
