// override bootstrap variables here
$font-family-sans-serif: Lato, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$min-contrast-ratio: 3;
$primary: #184999;
$dark: #231f20;

$enable-negative-margins: true;

$grid-gutter-width: 32px;
$btn-border-radius: 1.2em;
$btn-border-radius-lg: 1.2em;
$btn-border-radius-sm: 1.2em;
$btn-padding-x: 1.5rem;
$btn-padding-x-sm: .8rem;
$input-line-height: 2.2;
$font-size-base: 0.9rem;
$navbar-nav-link-padding-x: 1.3rem;
$navbar-dark-color: #fff;
$card-border-radius: 0;
$card-inner-border-radius: 0;

$carousel-control-width: 10%;
$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$carousel-indicator-spacer: 5px;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

$container-max-widths: ( sm: 1300px, md:1300px, lg:1300px, xl:1300px );
