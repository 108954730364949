.sorting {
    .btn-sm {
        font-size: .9rem;
        box-shadow: none;
        margin: 0 .25rem .25rem .25rem;
    }
    .btn-secondary {
        background: $gray-300;
        border-color: $gray-300;
        color: $primary;
    }
    .divider {
        display: none;
        width: 1px;
        height: 100%;
        background: $gray-300;
        vertical-align: middle;
        margin: 0 1rem;
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
    }
    .form-control {
        display: inline-block;
        width: auto;
        max-width: 80px;
    }
}
